/**************PRE LOADER*************/
$(window).on('load',function() {
    "use strict";
   $('.page-loader-wrapper').fadeOut('slow');
})

$(document).ready(function () {
    let width = $(window).width()
    if (width <= 480) return false;

    let menu_main_item = $('li.dropdown')
    let menu_link = menu_main_item.find('a.dropdown-toggle')
    menu_main_item.hover(fadeIn, fadeOut)

    menu_link.click(function (element) {

        let target = $(element.target)
        let href = target.prop('href')
        let shouldRedirect = (href.indexOf('#') === -1)

        if (shouldRedirect) {
            location.href = href
            return false
        }
    })

    function fadeIn(element) {
        $(element.currentTarget).addClass('open')
    }
    function fadeOut(element) {
        $(element.currentTarget).removeClass('open')
    }

});